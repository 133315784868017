section#presentation {
    #box_1 {
        -webkit-flex: 0 1 52%;
        -ms-flex: 0 1 52%;
        flex: 0 1 52%;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        -webkit-align-items: left;
        -ms-flex-align: left;
        align-items: left;
        .box-inner {
            width: 100%;
            padding: 60px 136px 30px 95px;
            p {
                width: 80%;
                @include font("400");
                font-size: 19px;
                line-height: 24px;
            }
        }
        .box-image-small {
            display: none;
            .image {
                height: 300px;
            }
        }
    }
    #box_2 {
        -webkit-flex: 0 1 48%;
        -ms-flex: 0 1 48%;
        flex: 0 1 48%;
    }
    .box-inner {
        min-height: 540px;
        background: $wh;
        color: $fontColor;
        p {
            color: $fontColor;
        }
    }
    .box-image {
        position: absolute;
        top: -60px;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .box-link {
            position: absolute;
            width: 182px;
            height: 182px;
            background: $blue;
            color: $wh;
            display: block;
            bottom: 80px;
            z-index: 3;
            padding: 30px 25px 22px 25px;
            left: -91px;
            text-align: center;
            span {
                @include font("300");
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                display: block;
                strong {
                    color: $darkBl;
                    @include font("700");
                    display: block;
                    font-size: 48px;
                    line-height: 48px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
section#centers_display {
    #box_1, #box_4, #box_5 {
        -webkit-flex: 0 1 42%;
        -ms-flex: 0 1 42%;
        flex: 0 1 42%;
    }
    #box_2, #box_3, #box_6 {
        -webkit-flex: 0 1 58%;
        -ms-flex: 0 1 58%;
        flex: 0 1 58%;
    }
    #box_3 {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: flex-end;
        justify-content: flex-end;
        -webkit-align-items: flex-end;
        -ms-flex-align: flex-end;
        align-items: flex-end;
        .box-inner {
            width: 88%;
            padding-right: 137px;
            padding-left: 115px;
        }
    }
    #box_2, #box_6 {
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        -webkit-align-items: left;
        -ms-flex-align: left;
        align-items: left;
        .box-inner {
            width: 88%;
            padding-right: 115px;
            padding-left:137px;
        }
    }
    #box_2 {
        background: $bgSport;
        .box-inner {
            background: $bgSportLight;
        }
    }
    #box_3 {
        background: $bgSante;
        .box-inner {
            background: $bgSanteLight;
        }
    }
    #box_6 {
        background: $bgBienetre;
        .box-inner {
            background: $bgBienetreLight;
        }
    }
    .box-inner {
        padding: 70px 100px 45px 100px;
        min-height: 540px;
        color: $fontColor;
        p {
           color: $fontColor; 
        }
    }
    #box_1 {
        .box-link {
            &:hover {
                background: $bgSport;
            }
        }
    }
    #box_4 {
        .box-link {
            &:hover {
                background: $bgSante;
            }
        }
    }
    #box_5 {
        .box-link {
            &:hover {
                background: $bgBienetre;
            }
        }
    }

    .box-link {
        position: absolute;
        width: 170px;
        height: 170px;
        background: $blue;
        color: $wh;
        display: block;
        bottom: 250px;
        z-index: 2;
        padding: 0 25px 22px 25px;
        overflow: hidden;
        @include animation(background, .3s);
        &:before {
            content: "";
            opacity: 0;
            width: 150px;
            height: 150px;
            left: 10px;
            top: 10px;
            border: solid 1px $wh;
            display: block;
            position: absolute;
            @include prefix(transform, 'scale(1.25)');
            @include animation(all, .5s);
        }
        &:hover {
            text-decoration: none;
            &:before {
                opacity: 0.5;
                @include prefix(transform, 'scale(1)');
            }
            i {
                //top: 11px;
                opacity: 0;
            }
            span {
                top: 56px;
            }
        }
        i {
            position: absolute;
            top: 45px;
            left: 25px;
            font-size: 30px;
            display: block;
            @include animation(opacity, .3s);
        }
        span {
            @include animation(top, .3s);
            position: absolute;
            top: 90px;
            left: 25px;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            display: block;
            width: 75%;
            em {
                font-style: inherit;
            }
        }
    }
}

.box-title {
    h2 {
        @include font("200");
        font-weight: 700;
        font-size: 35px;
        line-height: 44px;
        margin-bottom: 30px;
        span, em {
            @include font("700");
            color: $blue;
            font-style: normal;
        }
        &:after {
            content: "";
            margin-top: 25px;
            display: block;
            width: 184px;
            height: 8px;
            background-color: $blue;
        }
    }
    h3 {
        font-size: 64px;
        @include font("700");
        text-transform: uppercase;
        margin-bottom: 15px;
        span {
            @include font();
            padding-left: 5px;
            display: block;
            font-weight: 300;
            font-size: 24px;
        }
    }
}

section#centers_display #box_1 {
    .box-link {
        right: -85px;
    }
}
section#centers_display #box_4 {
    .box-link {
        left: -85px;
        //bottom: -50px;
    }
}

section#centers_display #box_5 {
    .box-link {
        right: -85px;
        //bottom: 200px;
    }
}

#wrapper {
    width: 100%;
    overflow: hidden;
    background: $wh;
    position: relative;
}
.section-cta {
    min-height: 260px;
    padding: 50px 0;
    width: 100%;
    margin-bottom: 60px;
    position: relative;
    overflow: hidden;
    margin-top: -50px;
    &:before {
        width: 100%;
        height: 24px;
        position: absolute;
        top: 0;
        background: url(../images/box-shaddow.png) repeat-x;
        content: "";
        z-index: 2;
    }
    &:after {
        width: 100%;
        height: 24px;
        position: absolute;
        bottom: 0;
        background: url(../images/box-shaddow.png) repeat-x;
        content: "";
        z-index: 2;
        -moz-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        transform: scale(1, -1);
    }
    .cta-bg {
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/kape-care-rdv.jpg);
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 200%;
    }
    .text {
        position: relative;
        color: $wh;
        @include font("300");
        color: $wh;
        font-size: 28px;
        line-height: 34px;
        strong {
            text-transform: uppercase;
            font-size: 32px;
            display: block;
        }
        margin-bottom: 15px;
    }
    a.action-link {
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 0;
        width: auto;
        position: relative;
        &:hover {
            span {
                -webkit-transform: none !important;
                -moz-transform: none !important;
                -ms-transform: none !important;
                -o-transform: none !important;
                transform: none !important;
            }
        }
        .icon {
            font-size: 40px;
        }
        span {
            font-size: 18px;
            line-height: 40px;
        }
    }
}
.section-hp {
    width: 86%;
    margin: 0 auto;
    //margin-top: -30px;
    position: relative;
    h3 {
        @include font("700");
        font-size: 144px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: -55px;
        &.sml {
            font-size: 100px;
        }
    }
    &.team-bloc {
        //margin-top: 100px;
        h3 {
            margin-bottom: -25px;
        }
    }
}
#hp_main_section {
    width: 86%;
    margin: 0 auto;
    padding-top: 60px;
    position: relative;
    .col {
        -webkit-flex: 0 1 50%;
        -ms-flex: 0 1 50%;
        flex: 0 1 50%;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        padding: 0;
        position: initial;
        &.col-img {
            padding: 15px 3%;
            img {
                padding-top: 0;
                max-height: calc(100% - 60px);
                position: absolute;
                bottom: 0;
                z-index: 10;
            }
        }
        &.col-text {
            padding-bottom: 50px;
        }
    }
    h2 {
        font-size: 36px;
        line-height: 44px;
        color: $fontColor;
        @include font("400");
        margin-bottom: 30px;
        padding-bottom: 35px;
        position: relative;
        &:after {
            content: "";
            width: 184px;
            height: 8px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 15%;
        }
    }
    p {
        padding: 0 15%;
        font-size: 16px;
        line-height: 24px;
    }
}

.section-bloc.section-hp.rellax {
    margin-top: 50px;
}
.section-bloc.section-hp.rellax.extra-marge {
    margin-top: 200px !important;
}
.section-bloc.section-hp {
    .owl-carousel {
        .owl-nav {
            display: none !important;
        }
    }
}
.disciplines-bloc.section-hp.m-t-100 {
    margin-bottom: 0;
}
.section-hp.team-bloc.team-bloc h3 {
    margin-bottom: -12px;
}

@media (max-width: 1024px) {
    .section-hp {
        h3 {
            font-size: 120px;
            margin-bottom: -40px;
        }
    }
}

@media (max-width: 992px) {
    section#presentation #box_1 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        -webkit-flex: 0 1 100% !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        //margin-top: -80px;
        margin-top: -150px;
        .box-inner {
            width: 100%;
            margin: 0 auto;
            margin-bottom: 30px;
            padding: 80px 20% 0 20%;
            text-align: center;
            h2 {
                &:after {
                    margin: 0 auto;
                    margin-top: 25px;
                }
            }
            p {
                width: 100%;
            }
        }
    }
    section#presentation #box_2 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -webkit-flex: 0 1 100% !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        height: 400px;
        margin-top: -150px;
        .box-image {
            width: 50%;
            height: 100%;
            left: 50%;
            margin-left: -25%;
            //top: -100px;
            .box-link {
               // display: none !important;
            }
        }
    }

    section#centers_display #box_1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        height: 400px;
        background-color: $bgSport;
        background-blend-mode: multiply;
        .box-link {
            left: auto;
            right: 0;
            bottom: 0;
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            padding: 20px;
            height: auto;
            width: 125px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    section#centers_display #box_2 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
        padding-top: 30px;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        .box-inner {
            padding: 30px;
            width: 100%;
            height: 100%;
            min-height: auto;
            .box-title {
                position: absolute;
                top: -120px;
                left: 30px;
                color: $wh;
                z-index: 3;
            }
        }
    }
    section#centers_display #box_3 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
        padding-top: 30px;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        .box-inner {
            padding: 30px;
            width: 100%;
            height: 100%;
            min-height: auto;
            .box-title {
                position: absolute;
                top: -120px;
                left: 30px;
                color: $wh;
                z-index: 3;
            }
        }
    }
    section#centers_display #box_4 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        height: 400px;
        background-color: $bgSante;
        background-blend-mode: multiply;
        .box-link {
            left: auto;
            right: 0;
            bottom: 0;
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            padding: 20px;
            height: auto;
            width: 125px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    section#centers_display #box_5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
        background-color: $bgBienetre;
        background-blend-mode: multiply;
        .box-link {
            padding: 20px;
            height: auto;
            width: 124px;
            right: -62px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    section#centers_display #box_6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        .box-inner {
            width: 93%;
            padding: 30px 30px 30px 80px;
        }
    }
    
    section#centers_display .box {
        -webkit-flex: 0 1 50% !important;
        -ms-flex: 0 1 50% !important;
        flex: 0 1 50% !important;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
    }

    section#centers_display {
        .box-link {
            &:before {
                width: 105px;
                height: 105px;
            }
            i {
                position: relative;
                top: auto;
                left: auto;
            }
            span {
                position: relative;
                top: auto !important;
                left: auto;
                margin-top: 15px;
                width: 85%;
            }
        }
    }

    #hp_main_section {
        .col {
            &.col-img {
                padding: 15px 3%;
            }
        }
        h2 {
            font-size: 26px;
            line-height: 34px;
            &:after {
                left: 8%;
            }
        }
        p {
            padding: 0 8%;
        }
    }
    .section-hp {
        h3 {
            font-size: 100px;
            margin-bottom: -30px;
        }
    }
    .section-cta {
        a.action-link {
            width: auto;
            .icon {
                font-size: 30px;
                width: 40px;
            }
            span {
                font-size: 14px;
                line-height: 30px;
                width: auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .section-hp, #hp_main_section {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
    .box-title {
        h3 {
            font-size: 52px;
        }
        h2 {
            font-size: 32px;
            line-height: 38px;
            span {
                display: block;
            }
        }
    }
    section#presentation .box-image {
        background-position: bottom;
        .box-link {
            bottom: -10px;
            left: 50%;
            width: 240px;
            margin-left: -120px;
            height: auto;
            padding-top: 22px;
        }
    }
    section#presentation #box_1 {
        margin-top: -120px;
        .box-inner {
            width: 100%;
            padding: 100px 5% 0 5%;
        }
    }
    section#presentation #box_2 {
        height: 300px;
        .box-image {
            width: 80%;
            margin-left: -40%;
        }
    }

    section#centers_display #box_1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        height: 400px;
        background-color: $bgSport;
        background-blend-mode: multiply;
        .box-link {
            left: auto;
            right: 0;
            bottom: 0;
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            padding: 20px;
            height: auto;
            width: 125px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    section#centers_display #box_2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    section#centers_display #box_3 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    section#centers_display #box_4 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    section#centers_display #box_5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
        height: 400px;
        .box-link {
            left: auto;
            right: 0;
            bottom: 0;
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            padding: 20px;
            height: auto;
            width: 125px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    section#centers_display #box_6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
        padding-top: 30px;
        .box-inner {
            padding: 30px;
            width: 100%;
            height: 100%;
            min-height: auto;
            .box-title {
                position: absolute;
                top: -120px;
                left: 30px;
                color: $wh;
                z-index: 3;
            }
        }
    }
    
    section#centers_display .box {
        -webkit-flex: 0 1 100% !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        .box-link {
            bottom: 0 !important;
            top: auto !important;
        }
    }

    #hp_main_section {
        .col {
            -webkit-flex: 0 1 100% !important;
            -ms-flex: 0 1 100% !important;
            flex: 0 1 100% !important;
            h2 {
                &:after {
                    left: 0;
                }
            }
            p {
                padding: 0;
            }
            &.col-text {
                margin-top: 40px;
                padding-bottom: 40px;
            }
            &.col-img {
                padding: 40px 0 0 0;
                img {
                    position: relative;
                    max-height: auto;
                    max-width: 100%;
                    bottom: auto;
                }
            }
        }
    }

    section#presentation #box_1 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        -webkit-flex: 0 1 100% !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        //margin-top: -80px;
        margin-top: -150px;
        .box-inner {
            width: 100%;
            margin: 0 auto;
            padding: 100px 20% 0 20%;
            text-align: center;
            h2 {
                &:after {
                    margin: 0 auto;
                    margin-top: 25px;
                }
            }
            p {
                width: 100%;
            }
        }
    }

}
@media (max-width: 700px) {
    .section-hp {
        h3 {
            font-size: 80px !important;
            margin-bottom: -28px;
        }
    }
}
@media (max-width: 600px) {
    .section-hp {
        h3 {
            font-size: 48px !important;
            margin-bottom: -15px;
        }
    }
}

@media (max-width: 543px) {
    section#presentation {
        position: relative;
        background: $wh;
        #box_1 {
            margin-top: 0 !important;
            .box-inner {
                text-align: left;
                padding: 30px 20% 0 20%;
            }
            .box-title h2 {
                margin-bottom: 0;
                font-size: 24px;
                line-height: 32px;
                width: 98%;
                span {
                    display: inline-block;
                }
                &:after {
                    margin: 25px 0 0 0;
                    width: 50%;
                }
            }
            .box-image-small {
                display: block;
                margin-bottom: 25px;
                position: relative;
                .image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .box-link {
                    position: absolute;
                    width: 150px;
                    right: 0;
                    top: 0;
                    background: $blue;
                    color: $wh;
                    padding: 38px 15px 22px 15px;
                    text-align: center;
                    &.bottom {
                        top: auto;
                        bottom: 0;
                    }
                    &.left {
                        right: auto;
                        left: 0;
                    }
                    span {
                        @include font("300");
                        font-size: 12px;
                        line-height: 14px;
                        text-transform: uppercase;
                        display: block;
                        strong {
                            color: $darkBl;
                            @include font("700");
                            display: block;
                            font-size: 38px;
                            line-height: 38px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        #box_2 {
            display: none;
            position: absolute;
            top: 300px;
            width: 100%;
            .box-image, .box-link {
                @include prefix(transform, none !important);
            }
            .box-image {
                width: 90%;
                margin-left: -45%;
                top: 0;
            }
        }
    }
}
@media (max-width: 430px) {
    .section-hp {
        h3 {
            font-size: 40px !important;
            margin-bottom: -12px;
            &.sml {
                font-size: 25px !important;
                margin-bottom: -8px !important;
            }
        }
    }
    .section-cta {
        a.action-link {
            span {
                line-height: 14px;
                width: 80%;
            }
        }
    }
}