/*
*	COLORS
*/
$wh: #FFFFFF;
$bl: #3d3d3d;
$darkBl: #212529;
$lightGrey: #cccccc;
$fontColor: #495057;
$blue: #48bed7;
$blueLight: lighten($blue, 10%);
$darkBlue: #4a5057;
$red: #f03e3e;

$bgSport: #99aad3;
$bgSportLight: #d5dcee;

$bgSante: #f3a697;
$bgSanteLight: #f9e7e4;

$bgBienetre: #99d1c8;
$bgBienetreLight: #d1eae6;

$action: #5371b9;



/*
*	FONTS
*/

$font-primary: 'Arial', sans-serif; 
$font-icons: 'FontAwesome';

.animated {
  -webkit-animation-duration: 0.5s;
     -moz-animation-duration: 0.5s;
       -o-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
       -o-animation-fill-mode: both;
          animation-fill-mode: both;
  will-change: transform, opacity;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translate3d(0px, -120%, 0px);
  }

  100% {
    -webkit-transform: translate3d(0px, 0, 0px);
  }
}

@-moz-keyframes slideDown {
  0% {
    -moz-transform: translate3d(0px, -120%, 0px);
  }

  100% {
    -moz-transform: translate3d(0px, 0, 0px);
  }
}

@-o-keyframes slideDown {
  0% {
    -o-transform: translate3d(0px, -120%, 0px);
  }

  100% {
    -o-transform: translate3d(0px, 0, 0px);
  }
}

@keyframes slideDown {
  0% {
    transform: translate3d(0px, -120%, 0px);
  }

  100% {
    transform: translate3d(0px, 0, 0px);
  }
}

.animated.slideDown {
  -webkit-animation-name: slideDown;
  -moz-animation-name: slideDown;
  -o-animation-name: slideDown;
  animation-name: slideDown;
}

body.m-menu-open .animated.slideDown {
  -webkit-animation-name: slideDownMobile;
  -moz-animation-name: slideDownMobile;
  -o-animation-name: slideDownMobile;
  animation-name: slideDownMobile;
}

@-webkit-keyframes slideDownMobile {
  0% {
    -webkit-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }

  100% {
    -webkit-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }
}

@-moz-keyframes slideDownMobile {
  0% {
    -moz-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }

  100% {
    -moz-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }
}

@-o-keyframes slideDownMobile {
  0% {
    -o-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }

  100% {
    -o-transform: translate3d(calc(100% - 55px), 0px, 0px);
  }
}

@keyframes slideDownMobile {
  0% {
    transform: translate3d(calc(100% - 55px), 0px, 0px);
  }

  100% {
    transform: translate3d(calc(100% - 55px), 0px, 0px);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translate3d(0px, 0, 0px);
  }

  100% {
    -webkit-transform: translate3d(0px, -120%, 0px);
  }
}
@-moz-keyframes slideUp {
  0% {
    -moz-transform: translate3d(0px, 0, 0px);
  }

  100% {
    -moz-transform: translate3d(0px, -120%, 0px);
  }
}
@-o-keyframes slideUp {
  0% {
    -o-transform: translate3d(0px, 0, 0px);
  }

  100% {
    -o-transform: translate3d(0px, -120%, 0px);
  }
}
@keyframes slideUp {
  0% {
    transform: translate3d(0px, 0, 0px);
  }

  100% {
    transform: translate3d(0px, -120%, 0px);
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}