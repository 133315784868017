header#header {
    height: 93px;
    &.large {
        height: 720px;
        overflow: hidden;
    }
    &.medium {
        height: 520px;
        .header-bg {
            top: 93px;
         }
        .header-presentation .text-container .text {
            font-size: 32px;
            line-height: 38px;
        }
    }
    &.base {
        width: 100%;
        #main_nav {
            -moz-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
            -webkit-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
            -o-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
            box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
        }
    }
    width: 93%;
    position: relative;
    overflow: hidden;
    @include animation(width, .3s);
    .header-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        z-index: 0;
        &.vid {
            background-color: $bgSport;
            background-blend-mode: multiply;
            mix-blend-mode: multiply;
        }
        video {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    // .header-bg {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     background-size: cover;
    //     background-position: center;
    //     z-index: 0;
    //     video {
    //         position: absolute;
    //         min-width: 100%;
    //         min-height: 100%;
    //         top: 0;
    //         left: 0;
    //         z-index: 0;
    //     }
    // }
    // .owl-header {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     z-index: 0;
    //     .owl-item {

    //     }
    //     .owl-stage-outer, .owl-stage, .owl-item {
    //         height: 100%;
    //     }
    // }
    .header-presentation {
    
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        position: absolute;
        top: 139px;
        left: 0;
        width: 100%;
        height: calc(100% - 139px);
        z-index: 1;
        .text-container {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: center;
            -ms-flex-item-align: center;
            align-self: center;
            padding-left: 95px;
            width: 80%;
            h1.tag-center {
                margin-bottom: 15px;
            }
            .note {
                margin-bottom: 15px;
                text-transform: uppercase;
                color: $wh;
                display: block;
                span.main {
                    display: inline-block;
                    background: $blue;
                    float: left;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 0 4px;
                }
                span.second {
                    background: $darkBl;
                    display: inline-block;
                    float: left;
                    clear: both;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 0 4px;
                }
                &:after {
                    display: table;
                    clear: both;
                    content: "";
                }
            }
            .text {
                display: block;
                @include font("300");
                color: $wh;
                font-size: 48px;
                line-height: 58px;
                strong {
                    text-transform: uppercase;
                    font-size: 32px;
                }
            }
        }
    }

    &.sticky {
        .main-nav {
            .logo {
                img {
                   height: 62px;
                   top: 5px;
                }
            }
            #menu_button {
                margin: 28px 25px 0 0;
            }
            nav.menu {
                ul li {
                    a {
                        height: 60px;
                        line-height: 60px;
                    }
                }
            }
        }
    }
    .main-nav {
        background: $wh;
        position: fixed;
        z-index: 999;
        width: 100%;
        overflow: hidden;
        .logo {
            width: 260px;
            background: $wh;
            padding: 0 9px;
            display: inline-block;
            float: left;
            position: relative;
            @include animation(width, .3s);
            img {
                height: 75px;
                display: block;
                right: 0;
                top: 9px;
                position: absolute;
                @include animation(all, .3s);
            }
            span {
                width: 149px;
                height: 75px;
                display: block;
                float: right;
                background: url("../images/kap-care.png") no-repeat;
                @include image-2x("../images/kap-care@2x.png", 149px, 75px);
                text-indent: -9999px;
                @include animation(all, .3s);
            }
        }
        nav.menu {
            display: inline-block;
            float: right;
            margin-right: 7%;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    display: block;
                    float: left;
                    font-size: 14px;
                    font-weight: 700;
                    a {
                        display: block;
                        height: 93px;
                        line-height: 93px;
                        color: $fontColor;
                        text-transform: uppercase;
                        padding: 0 15px 0 15px;
                        background: $wh;
                        @include animation(all, .3s);
                        position: relative;
                        &:after {
                            @include animation(opacity, .3s);
                            content: "";
                            position: absolute;
                            bottom: 0;
                            // width: calc(100% - 30px);
                            // left: 15px;
                            width: calc(100%);
                            left: 0;
                            height: 4px;
                            background: $blue;
                            display: block;
                            opacity: 0;
                        }
                        &:hover {
                            text-decoration: none;
                            color: $blue;
                            &:after {
                                opacity: 1;
                            }
                        }
                        &.user {
                            background: $blue !important;
                            color: $wh;
                            i {
                                font-size: 28px;
                                margin-right: 8px;
                            }
                            &:hover {
                                background: lighten($blue, 15%);
                                color: $wh !important;
                            }
                            &:after {
                                display: none;
                            }
                        }
                        // &.kap-sport {
                        //     &:hover {
                        //         background: $bgSport;
                        //         //color: $darkBl;
                        //     }
                        // }
                        // &.kap-sante {
                        //     &:hover {
                        //         background: $bgSante;
                        //         //color: $darkBl;
                        //     }
                        // }
                        // &.kap-bien-etre {
                        //     &:hover {
                        //         background: $bgBienetre;
                        //         //color: $darkBl;
                        //     }
                        // }
                    }
                    &.current {
                        a {
                            color: $blue;
                            &:after {
                                opacity: 1;
                            }
                            //background: lighten($blue, 40%);
                        }   
                    }
                }
            }
        }
    }
    .large {
        .headroom--not-top {
            &.slideDown, &.headroom--not-bottom {
                -moz-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
                -webkit-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
                -o-box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
                box-shadow: 0px 3px 15px 0px rgba(#656565, 0.5);
            }
            &.slideUp {
                -moz-box-shadow: none !important;
                -webkit-box-shadow: none !important;
                -o-box-shadow: none !important;
                box-shadow: none !important;
            }
        }
    }
}

#menu_button {
    position: relative;
    display: none !important;
    height: 48px;
    float: right;
    margin: 38px 25px 7px 0;
    @include animation(all, .5s);
    .menu-label {
        float: left;
        display: block;
        margin-right: 10px;
        text-transform: uppercase;
        margin-top: -7px;
        font-size: 14px;
        color: $blue;
    }
    #menu_icon {
        cursor: pointer;
        display: block;
        float: left;
        span {
            background: $blue;
            display: block;
            width: 38px;
            height: 2px;
            margin-bottom: 3px;
            position: relative;
            top: 0;
            @include animation(all, .25s);
            left: 5px;
            &:nth-child(2){
                left: 10px;
            }
            &:nth-child(3){
                left: 0;
            }
        }
    }
    &.open {
        #menu_icon {
            span {
                &:nth-child(2){
                    width: 0;
                    opacity: 0;
                }
                &:nth-child(3){
                    width: 0;
                    opacity: 0;
                }
                &:nth-child(1){
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    top: 5px;
                    left: 0;
                }
                &:nth-child(4){
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: -10px;
                    left: 0;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    header#header {
        .main-nav {
            .logo {
                width: 198px;
            }
            nav.menu {
                left: 198px;
            }
        }
        .header-presentation .text-container {
            padding-left: 50px;
        }
    }
}

@media (max-width: 1150px) {
    header#header {
        width: 100%;
        .main-nav {
            nav.menu {
                .link-news {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .link-news {
        display: none;
    }
    header#header {
        .header-presentation {
            top: 40px;
            .text-container {
                .text {
                    font-size: 38px;
                    line-height: 42px;
                    strong {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

// Menu
@media (max-width: 992px) {
    #menu_button {
        display: block !important;
    }
    header#header {
        overflow: visible;
        &.medium {
            overflow: hidden;
        }
        &.sticky {
            .main-nav {
                .logo {
                    width: 139px;
                }
                nav.menu {
                    top: 76px;
                }
            }

        }
        .main-nav {
            overflow: visible;
            .logo {
                width: 164px;
            }
            nav.menu {
                float: none;
                position: fixed;
                padding-top: 25px;
                top: 93px;
                left: 0;
                top: 93px;
                background: darken($wh, 5%);
                z-index: 999;
                margin: 0;
                height: 0;
                width: 100%;
                overflow: hidden;
                display: block;
                //visibility: hidden;
                opacity: 0;
                @include animation(all, .25s);
                ul {
                    li {
                        width: calc(50% - 20px);
                        height: 60px;
                        position: relative;
                        margin-right: 15px;
                        &.link-news {
                            display: inherit;
                        }
                        a {
                            position: absolute;
                            width: 100%;
                            height: 60px;
                            opacity: 0;
                            left: 10px;
                            @include animation(all, 0s);
                            -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
                            transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);


                            -webkit-transform: translate3d(0, 600%, 0);
                            transform: translate3d(0, 600%, 0);

                        }

                        
                    }
                }
            }
        }
        .header-presentation {
            //top: 0;
        }
    }
    body.menu-open {
        overflow: hidden;
        header#header {
            
            .main-nav {
                overflow: visible;
                
                nav.menu {
                    //visibility: visible;
                    opacity: 1;
                    height: 100vh;
                    overflow-y: scroll;
                    ul {
                        li {
                            float: none;
                            margin-bottom: 10px;
                            text-align: center;
                            float: left;
                            a {
                                @include animation(all, .5s);
                                padding: 15px;
                                background: lighten($blue, 15%);
                                text-align: center;
                                //height: auto !important;
                                line-height: inherit !important;
                                color: $wh;
                                opacity: 1;

                                -webkit-transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
                                transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
                                -webkit-transform: translate3d(0, 0, 0);
                                transform: translate3d(0, 0, 0);

                                
                                &.kap-sport {
                                    background: $bgSport;
                                    
                                }
                                &.kap-sante {
                                    background: $bgSante;
                                    
                                }
                                &.kap-bien-etre {
                                    background: $bgBienetre;
                                    
                                }
                                &.user {
                                    color: $wh;
                                }
                            }
                            &:nth-child(1) {
                                a {
                                    @include prefix(transition-delay, 0.25s);
                                }
                            }
                            &:nth-child(2) {
                                a {
                                    @include prefix(transition-delay, 0.30s);
                                }
                            }
                            &:nth-child(3) {
                                a {
                                    @include prefix(transition-delay, 0.35s);
                                }
                            }
                            &:nth-child(4) {
                                a {
                                    @include prefix(transition-delay, 0.40s);
                                }
                            }
                            &:nth-child(5) {
                                a {
                                    @include prefix(transition-delay, 0.45s);
                                }
                            }
                            &:nth-child(6) {
                                a {
                                    @include prefix(transition-delay, 0.50s);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    header#header {
        .header-presentation {
            .text-container {
                padding-left: 30px;
                padding-right: 30px;
                width: 100%;
                .text {
                    
                }
            }
        }
    }
}

@media (max-width: 543px) {
    body.menu-open {
        header#header {
            .main-nav {
                nav.menu {
                    ul {
                        li {
                            width: 100%;
                            a {
                                width: 260px;
                                left: 50%;
                                margin-left: -130px;
                            }
                        }
                    }
                }
            }
        }
    }
    header#header.medium .header-presentation .text-container .text {
        font-size: 24px;
        line-height: 30px;
    }
    header#header {
        &.large {
            height: 50vh;
        }
        .header-presentation {
            height: 100%;
            display: block;
            padding-top: 115px;
            .text-container {
                padding: 0 15px;
                h1.tag-center {
                    font-size: 22px;
                    line-height: 32px;
                    padding: 8px 14px;
                }
                .text {
                    position: absolute;
                    bottom: 70px;
                    font-size: 24px;
                    line-height: 32px;
                    padding-right: 15px;
                    strong {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}