@font-face {
    font-family: 'BrandonGrotesque-MediumItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-MediumItalic.svg#BrandonGrotesque-MediumItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin font($weight:"", $italic:"") {
    @if $weight == '' {
        font-family: 'BrandonGrotesque-Regular', arial;
    }
    @if $weight == '100' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-ThinItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Thin', arial;
        }
    }
    @if $weight == '200' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-LightItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Light', arial;
        }
    }
    @if $weight == '300' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-MediumItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Medium', arial;
        }
    }
    @if $weight == '400' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-RegularItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Regular', arial;
        }
    }
    @if $weight == '700' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-BoldItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Bold', arial;
        }
    }
    @if $weight == '900' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-BlackItalic', arial;
        } @else {
            font-family: 'BrandonGrotesque-Black', arial;
        }
    }
}

@mixin font_ex($weight:"", $italic:"") {
    @if $weight == '' {
        font-family: 'BrandonGrotesque-Regular', arial !important;
    }
    @if $weight == '100' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-ThinItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Thin', arial !important;
        }
    }
    @if $weight == '200' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-LightItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Light', arial !important;
        }
    }
    @if $weight == '300' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-MediumItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Medium', arial !important;
        }
    }
    @if $weight == '400' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-RegularItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Regular', arial !important;
        }
    }
    @if $weight == '700' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-BoldItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Bold', arial !important;
        }
    }
    @if $weight == '900' {
        @if $italic != '' {
            font-family: 'BrandonGrotesque-BlackItalic', arial !important;
        } @else {
            font-family: 'BrandonGrotesque-Black', arial !important;
        }
    }
}

@font-face {
    font-family: 'BrandonGrotesque-Regular';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Regular.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Regular.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Regular.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Regular.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Light';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Light.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Light.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Light.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Light.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Thin';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Thin.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Thin.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Thin.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Thin.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Thin.svg#BrandonGrotesque-Thin') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-ThinItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-ThinItalic.svg#BrandonGrotesque-ThinItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-RegularItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-RegularItalic.svg#BrandonGrotesque-RegularItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Medium';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Medium.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Medium.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Medium.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Medium.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Black';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Black.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Black.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Black.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Black.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-BlackItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-BlackItalic.svg#BrandonGrotesque-BlackItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Bold';
    src: url('../fonts/brandon/subset-BrandonGrotesque-Bold.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Bold.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-Bold.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-Bold.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-BoldItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-BoldItalic.svg#BrandonGrotesque-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-LightItalic';
    src: url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.eot');
    src: url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.woff2') format('woff2'),
        url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.woff') format('woff'),
        url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.ttf') format('truetype'),
        url('../fonts/brandon/subset-BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}