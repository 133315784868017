$elementSeparator: '__';
$modifierSeparator: '--';

@mixin block($block) {
    .#{$block} {
        @content;
    }
}

@mixin element($element) {
    @at-root {
        #{&}#{$elementSeparator+$element} {
            @content;
        }
    }
}

@mixin modifier($modifier) {
    @at-root {
        #{&}#{$modifierSeparator+$modifier} {
            @content;
        }
    }
}

@mixin animation ( $duration, $animation) {
    -webkit-transition: $animation $duration ease;
    -moz-transition: $animation $duration ease;
    -ms-transition: $animation $duration ease;
    -o-transition: $animation $duration ease;
    transition: $animation $duration ease;
}

@mixin img-cover {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 100%;
}

@mixin prefix($property, $value) {
    -webkit-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}