header#header {
    .main-nav {
        nav.menu {
            ul {
                li {
                    a {
                        &.kap-bien-etre {
                            &:hover {
                                color: $bgBienetre !important;
                            }
                            &:after {
                                background: $bgBienetre !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.univers-bien-etre {
    header#header .header-bg video {
        left: auto;
        right: 0;
    }
    .breadcrumbs a {
        color: $bgBienetre;
    }
    .content h2 {
        color: $bl;
    }
    .content h4 {
        color: $bgBienetre;
    }
    header#header {
        .main-nav {
            nav.menu {
                ul {
                    li {
                        &.current {
                            a {
                                color: $bgBienetre;
                            }
                        }
                        a {
                            &:hover {
                                color: $bgBienetre;
                            }
                            &:after {
                                background: $bgBienetre;
                            }
                            &.user {
                                background: $bgBienetre;
                                &:hover {
                                    background: darken($bgBienetre, 15%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-presentation .text-container h1.tag-center {
            background: $bgBienetre;
        }
    }
    header#header .main-nav nav.menu ul li a.user {
        background: $bgBienetre !important;
    }

    #hp_main_section {
        h2 {
            &:after {
                background-color: $bgBienetre;
            }
        }
    }
    .section-hp {
        h3 {
            color: $bgBienetreLight;
        }
    }
    .care-bloc {
        .item {
            &:hover {
                .item-bg {
                    background-color: $bgBienetre;
                }
            }
        }
    }
    a.action-link {
        background: $bgBienetre;
    } 
    .section-cta {
        a.action-link {
            background: $bgBienetre;
            &:hover {
                background: lighten($bgBienetre, 5%);
            }
        }
    }
    .single-view {
        .base-title {
            h1 {
                color: $bgBienetre;
                span {
                    color: $bgBienetreLight;
                }
            }
        }
    }
    .page-title {
        h1 {
            color: $bgBienetreLight;
        }
    }
    .single-title {
        h1 {
            color: $bgBienetreLight !important;
        }
    }
    .cms-view {
        .single-title {
            h1 {
                color: $bgBienetre;
            }
        }
    }
    .owl-carousel {
        .owl-prev, .owl-next {
            background: $bgBienetre;
        }
    }
    .content h3 {
        color: $bgBienetre;
    }
    #contact_page .ph, #contact_page .em a {
        color: $bgBienetre;
    }
    #contact_page .actions .btn-send {
        background: $bgBienetre;
    }
    .care-bloc .item .item-card h4 {
       
    }
    .care-bloc .item .item-card .more {
        color: $bgBienetre;
    }
    a.large-button {
        background: $bgBienetre;
    }
    .owl-dots .owl-dot.active span {
        background: $bgBienetre;
    }
    .care-bloc .item {
        padding-top: 0;
        .item-bg {
            position: relative;
            width: 100%;
            background-position: bottom center;
            height: 235px;
        }
        .item-card {
            margin: -2px 0 0 0;
            border-right: solid 1px #e6e6e6;
            h4 {
                 background: $bgBienetre;
                 border-left: solid 1px $bgBienetre;
                 border-right: solid 1px $bgBienetre;
                 margin: 0 -40px 20px -20px;
            }
        }
    }
    .care-bloc .owl-carousel-care .item .item-card {
        width: 100%;
    }
}
.univers-bien-etre {
    header.medium {
        .header-bg {
            background-color: $bgBienetreLight;
            background-blend-mode: multiply;
        }
    }
}

$unit-tiny: 4px;
$unit-large: 32px;

.univers-bien-etre {
    header#header .header-presentation .mtext {
        position: absolute;
        left: 95px;
        bottom: 0;
        width: 350px;
        padding: 20px;
        font-family: 'BrandonGrotesque-Medium', arial;
        color: #fff;
        font-size: 48px;
        background: rgba(0, 0, 0, 0.4);
        line-height: 58px;
    }

    header#header .header-presentation .text-container {
        align-self: auto;
    }
}

.care-datas {
    margin: 0;
    margin-top: 20px;
    .wrapper {
      padding: 2em 1em;
      background: #fff;
      columns: 50% 2;
      column-gap: $unit-large;
    }
    .descriptiopn {
        list-style-type: none;
        padding-left: 12px;
        overflow: hidden;
        display: inherit;
        margin-top: 18px;
        margin-bottom: 8px;
        line-height: 18px;
        width: 80%;
    }
    .item {
      border-bottom: 1px dotted #c5c5c5;
      break-inside: avoid;
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    span {
      margin-bottom: -20px;
      padding: 10px 4px 15px 15px;
      background:$wh;
      &.other {
        position: absolute;
        right: 0;
        margin-top: -13px;
        background: none;
        text-align: right;
        line-height: 22px;
      }
      &.price em {
            font-style: normal;
            font-weight: 700;
      }
    }
    .list-title {
      font-size: 30px;
      color: $bgBienetre;
      margin-bottom: $unit-large;
      text-transform: uppercase;
    }
    .list {
      margin-bottom: $unit-large;
      overflow: hidden;
      .name {
        font-weight: 700;
        padding-right: 15px;
        text-transform: uppercase;
      }
    }
}

.moments-list {
    margin-top: 20px;
    .item {
        background: #f8f8f8;
        margin-bottom: 15px;
        .img-box, .txt-box {
            padding-top: .9375rem;
            padding-bottom: .9375rem;
        }
        .img-box {
            position: relative;
            .img {
                position: absolute;
                top: .9375rem;
                left: .9375rem;
                width: calc(100% - .9375rem);
                height: calc(100% - 1.875rem);
                background-size: cover;
                background-position: center;
            }
        }
        .txt-box {
            .content {
                margin-top: 0;
                background: $wh;
                padding: 15px;
                text-align: center;
                p {
                    margin-bottom: 0px;
                }
            }
            .logo {
                text-align: center;
                img {
                    max-height: 100px;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .univers-bien-etre {
        &.menu-open header#header .main-nav nav.menu ul li a {
            background: $bgBienetreLight;
            color: $bgBienetre;
        }
    }
    .univers-bien-etre {
        header#header .header-presentation {
            height: calc(100% - 240px);
            top: 110px;
        }
    }
}

@media (max-width: 768px) {
    .moments-list .item .img-box .img {
        width: calc(100% - 1.875rem);
    }
}

@media (max-width: 430px) {
    .univers-bien-etre header#header .header-presentation .text-container {
        margin-top: 40px;
    }
    .univers-bien-etre header#header .header-presentation .mtext {
        left: 15px;
        width: calc(100% - 30px);
        padding: 20px 15px;
        top: 0;
        h2 {
            font-size: 26px;
            line-height: 32px;
        }
    }
    .section-bloc.section-hp.rellax, .section-bloc.section-hp.rellax.extra-marge {
        margin-top: -100px !important; 
    }
}