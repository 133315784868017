.team-bloc {
    .owl-dots-container {
        margin-top: 20px;
    }
    .button-all {
        margin-top: 20px;
        text-align: center;
    }
    &.inline-cms {
        .item {
            margin-bottom: 15px;
        }
    }
    .list {
        .box {
            -webkit-flex: 0 1 50%;
            -ms-flex: 0 1 50%;
            flex: 0 1 50%;
            height: 260px;
            display: block;
            background: #212121;
            overflow: hidden;
            .team-img {
                width: 50%;
                float: left;
                height: 260px;
            }
            .team-info {
                width: 50%;
                float: left;
                height: 260px;
            }
            &:nth-child(odd) {
                background: #f5f5f5;
            }
            &:nth-child(3n+3) {
                background: #212121;
                .team-img {
                    float: right;
                }
            }
             &:nth-child(3n+4) {
                background: #f5f5f5;
                .team-img {
                    float: right;
                }
            }
        }
    }
    .card.card-shadow {
        border: 0px;
        margin-bottom: 30px;
        border-radius: 0;
        -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.25);
        box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.25);
        .card-body {
            padding: 40px;
            .text-info {
                color: $bgSante !important;
                @include font("700");
                font-size: 22px;
                font-weight: 500;
                a {
                    color: $bgSante !important;
                }
            }
        }
        h5 {
            @include font("700");
            font-weight: 500;
            font-size: 18px;
        }
    }
}

.team-bloc {
    .button-all {
        margin-top: 20px;
        text-align: center;
    }
}

.single-team {
    .main-presentation {
        margin-top: 50px;
        text-align: center;
        h1 {
            text-transform: uppercase;
        }
        .button-all {
            margin-top: 30px;
        }
    }
    
    .short-content {
        width: 70%;
        margin: 0 auto;
        margin-top: 30px;
        font-size: 18px;
        line-height: 22px;
        a {
            color: darken($bgSante, 5%);
        }
    }
   .pro-container {
        margin-top: 45px;
        .flex-row {
            background: $bgSanteLight;
        }
        .box {
            -webkit-flex: 0 1 50%;
            -ms-flex: 0 1 50%;
            flex: 0 1 50%;
            -webkit-justify-content: left;
            -ms-flex-pack: left;
            justify-content: left;
            -webkit-align-items: left;
            -ms-flex-align: left;
            align-items: left;
            min-height: 380px;
            .content {
                padding: 40px 0 40px 8%;
                width: 80%;
                a {
                    color: darken($bgSante, 5%);
                }
            }
        }
   }
   h1 {
        @include font("700");
        font-size: 50px;
        line-height: 50px;
        color: $bgSante;
    }
    h3 {
        color: $bl !important;
        font-size: 18px;
        margin-bottom: 30px;
    }
}

@media (max-width: 1200px) {
    
}
@media (max-width: 1024px) {
    
}
@media (max-width: 768px) {
    
}