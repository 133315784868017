footer#footer {
    margin-top: 110px;
    background: $darkBl;
    padding: 100px 0 15px 0;
    .container {
        position: relative;
    }
    .to-top {
        width: 100%;
        left: 0;
        padding: 10px 20px;
        background: $blue;
        position: absolute;
        top: -146px;
        display: block;
        @include animation(background, .3s);
        &:hover {
            background: lighten($blue, 10%);
        }
        .logo {
            width: 183px;
            height: 71px;
            display: block;
            background: url("../images/kap-care-footer.png") no-repeat;
            @include image-2x("../images/kap-care-footer@2x.png", 183px, 71px);
            float: left;
        }
        span {
            float: right;
            display: block;
            line-height: 71px;
            color: $wh;
            font-size: 28px;
        }
    }
    .footer-container {
        color: $lightGrey;
        p {
            width: 90%;
            font-size: 18px;
            line-height: 28px;
        }
        h4 {
            color: $wh;
            font-size: 18px;
            @include font("700");
            text-transform: uppercase;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                display: block;
                margin-bottom: 8px;
                font-size: 18px;
                line-height: 24px;
                a {
                    color: $lightGrey;
                }
            }
        }
        .contacts {
            color: $blue;
            @include font("200");
            font-size: 38px;
            line-height: 42px;
            a {
                font-size: 32px;
                line-height: 34px;
                color: $blue;
            }
            .em a {
                &:hover, &:focus, &:visited {
                    text-decoration: none;
                }
            }
            a.ph {
                display: block;
                font-size: 38px;
                line-height: 42px;
                &:hover, &:focus, &:visited {
                    text-decoration: none;
                }
            }
            span {
                display: block;
            }
            .contact {
                margin-top: 8px;
                a {
                    @include font("400");
                    font-weight: 700;
                    color: $wh;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
    .absolute-footer {
        margin-top: 40px;
        padding-top: 15px;
        border-top: solid 1px rgba(255, 255, 255, 0.15);
        color: $lightGrey;
        a {
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            margin-right: 35px;
            color: $lightGrey;
        }
    }
}

section#centers_presentation {
    margin-top: 50px;
    .box {
        -webkit-flex: 0 1 50%;
        -ms-flex: 0 1 50%;
        flex: 0 1 50%;
        &.box-sport {
            background: $bgSport;
            .box-inner {
                background: $bgSportLight;
            }
        }
        &.box-sante {
            background: $bgSante;
            .box-inner {
                background: $bgSanteLight;
            }
        }
        &.box-bien-etre {
            background: $bgBienetre;
            .box-inner {
                background: $bgBienetreLight;
            }
        }
    }
    
    #box_1 {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: flex-end;
        justify-content: flex-end;
        -webkit-align-items: flex-end;
        -ms-flex-align: flex-end;
        align-items: flex-end;
        .box-inner {
            width: 86%;
            // padding-right: 137px;
            // padding-left: 115px;
        }
        .box-link {
            left: -50px;
        }
    }
    #box_2 {
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        -webkit-align-items: left;
        -ms-flex-align: left;
        align-items: left;
        .box-inner {
            width: 86%;
            // padding-right: 115px;
            // padding-left:137px;
        }
        .box-link {
            right: -50px;
        }
    }
   
    .box-inner {
        padding: 40px 40px 375px 40px;
        color: $fontColor;
        position: relative;
        p {
           color: $fontColor; 
        }
    }
    .box-title {
        a {
            color: $fontColor;
        }
        h3 {
            font-size: 36px;
            @include font("400");
        }
        //margin: 0 40px;
    }
    .box-text {
        font-size: 18px;
        line-height: 23px;
        min-height: 120px;
    }
    .box-img {
        height: 330px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center;
    }
    .box-link {
        position: absolute;
        width: 170px;
        height: 170px;
        background: $blue;
        color: $wh;
        bottom: 40px;
        display: block;
        z-index: 2;
        padding: 0 25px 22px 25px;
        overflow: hidden;
        @include animation(background, .3s);
        &:before {
            content: "";
            opacity: 0;
            width: 150px;
            height: 150px;
            left: 10px;
            top: 10px;
            border: solid 1px $wh;
            display: block;
            position: absolute;
            @include prefix(transform, 'scale(1.25)');
            @include animation(all, .5s);
        }
        &:hover {
            text-decoration: none;
            &:before {
                opacity: 0.5;
                @include prefix(transform, 'scale(1)');
            }
            i {
                //top: 11px;
                opacity: 0;
            }
            span {
                top: 56px;
            }
        }
        i {
            position: absolute;
            top: 45px;
            left: 25px;
            font-size: 30px;
            display: block;
            @include animation(opacity, .3s);
        }
        span {
            @include animation(top, .3s);
            position: absolute;
            top: 90px;
            left: 25px;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            display: block;
            width: 75%;
            em {
                font-style: inherit;
            }
        }
    }
}

@media (max-width: 992px) {
    section#centers_presentation {
        .box-link {
            padding: 20px;
            height: auto;
            width: 124px;
            right: -62px;
            i {
                font-size: 20px;
                position: relative;
                top: auto;
                left: auto;
            }
            span {
                font-size: 14px;
                line-height: 16px;
                position: relative;
                top: auto !important;
                left: auto;
                margin-top: 15px;
                width: 85%;
            }
            &:before {
                width: 105px;
                height: 105px;
            }
        }
    }
    footer#footer {
        padding-top: 70px;
        .to-top {
            top: -120px;
        }
        .footer-container {
            .footer-text {
                margin-bottom: 30px;
            }
            p {
                width: 100%;
            }
            .contacts {
                font-size: 22px;
                line-height: 24px;
                font-weight: 700;
                a {
                    font-size: 18px;
                    line-height: 22px;
                }
                .contact {
                    margin-top: 5px;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    section#centers_presentation .box {
        -webkit-flex: 0 1 100% !important;
        -ms-flex: 0 1 100% !important;
        flex: 0 1 100% !important;
        &.box-sport {
            .box-img {
                // background-color: $bgSport;
                // background-blend-mode: multiply;
            }
        }
        &.box-sante {
            .box-img {
                // background-color: $bgSante;
                // background-blend-mode: multiply;
            }
        }
        &.box-bien-etre {
            .box-img {
                // background-color: $bgBienetre;
                // background-blend-mode: multiply;
            }
        }
        .box-inner {
            margin-bottom: 30px;
            width: 100% !important;
            padding-top: 360px;
            padding-bottom: 40px;
        }
        .box-img {
            bottom: auto;
            top: 0;
        }
        .box-link {
            left: auto !important;
            right: 0 !important;
            bottom: auto !important;
            //top: 0 !important;
            top: 207px !important;
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            padding: 20px;
            height: auto;
            width: 125px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
        .box-title {
            margin-bottom: 30px;
        }
    }
    footer#footer {
        text-align: center !important;
        .footer-container {
            .contacts {
                font-size: 38px;
                line-height: 42px;
                margin-bottom: 45px;
                 a {
                    font-size: 32px;
                    line-height: 34px;
                 }
                 .contact {
                    margin-top: 0;
                    a {
                        font-size: 16px;
                        line-height: 18px;
                    }
                 }
            }
        }
    }

}

@media (max-width: 543px) {
    footer#footer {
        margin-top: 85px;
        padding: 70px 0 15px 0;
        .to-top {
            width: auto;
            left: 30px;
            top: -120px;
            right: 30px;
        }
        .footer-container {
            padding: 0 15px;
            .footer-text {
                text-align: left !important;
            }
            p {
                width: 100%;
            }
            .contacts {
                text-align: left !important;
                span {
                    display: block;
                }
            }
        }
        .col-footer-access {
            float: left;
            width: auto;
            text-align: left;
            &:last-child {
                h4 {
                    &:after {
                        display: none;
                    }
                }
            }
            h4 {
                &:after {
                    content:"/";
                    display: inline-block;
                    margin-left: 24px;
                }
            }
        }
        .absolute-footer {
            border-top: 0;
            margin-top: 0;
            text-align: left !important;
            .copy {
                padding: 15px 0 0 0;
                margin-left: 30px;
                margin-right: 30px;
                border-top: solid 1px rgba(255, 255, 255, 0.15);
            }
            div {
                padding-left: 30px;
            }
        }
    }
}