#contact_page {
    address {
        @include font("400");
        font-size: 16px;
        line-height: 22px;
        display: block;
        margin-top: 15px;
        color: #666666;
        margin-bottom: 0;
    }
    .hours {
        font-size: 16px;
        line-height: 22px;
        display: block;
        margin-top: 13px;
        color: #666666;
    }
    .ph {
        display: block;
        margin-top: 16px;
        @include font("400");
        font-size: 24px;
        color: $blue;
    }
    .em {
        display: block;
        margin-top: 10px;
        @include font("400");
        font-size: 24px;
        a {
            color: $blue;
        }
    }
    #c_recaptcha {
        opacity: 0;
        visibility: hidden;
        height: 1px;
        width: 1px;
        position: absolute;
    }
    .text {
        @include font("300");
        color: $darkBlue;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 40px;
    }
    label {
        color: #494645;
        font-size: 13px;
        text-transform: uppercase;
        @include font("700");
        line-height: 24px;
        em {
            @include font("700");
            color: $red;
        }
    }
    .has-error {
        input, textarea {
            border-color: $red;
        }
    }
    span.error {
        @include font("300");
        font-size: 14px;
        color: $red;
    }
    input, textarea {
        padding: 20px;
        border-color: #dbd4d0;
    }
    textarea {
        height: 170px;
    }
    .notices-action {
        display: block;
        margin-top: 10px;
        .form-group {
            display: inline-block;
            float: left;
        }
    }
    .g-recaptcha {
        display: block;
        margin-top: 10px;
    }
    .notice {
        color: #494645;
        font-size: 13px;
        @include font("700");
        line-height: 24px;
        display: block;
        em {
            @include font("700");
            color: $red;
            display: inline-block;
            margin-right: 5px;
        }
    }
    .actions {
        float: right;
        .btn-send {
            background: $action;
            color: $wh;
            @include font("700");
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            text-align: center;
            border-radius: 0;
            border: none;
            display: block;
            padding: 15px 60px;
        }
    }
}