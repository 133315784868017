/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }
  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    img {
      display: block;
      width: 100%;
    }
  }
  .owl-nav.disabled, .owl-dots.disabled {
    display: none;
  }
  .owl-nav {
    .owl-prev, .owl-next {
      cursor: pointer;
      cursor: hand;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &.owl-loaded {
    display: block;
  }
  &.owl-loading {
    opacity: 0;
    display: block;
  }
  &.owl-hidden {
    opacity: 0;
  }
  &.owl-refresh .owl-item {
    visibility: hidden;
  }
  &.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &.owl-grab {
    cursor: move;
    cursor: grab;
  }
  &.owl-rtl {
    direction: rtl;
    .owl-item {
      float: right;
    }
  }
}

/* No Js */

.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel {
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }
    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }
  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
    &:hover {
      -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3);
    }
  }
  .owl-video-playing {
    .owl-video-tn, .owl-video-play-icon {
      display: none;
    }
  }
  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }
  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}
.owl-dots-container {
  text-align: center;
}
.owl-dots {
  display: inline-block;
  margin: 0 auto;
  .owl-dot {
    float: left;
    margin-right: 15px;
    &.active {
      span {
        width: 45px;
        border-radius: 5px;
        background: $blue;
      }
    }
    span {
      display: block;
      width: 8px;
      height: 8px;
      background: #d8d8d8;
      border-radius: 50%;
      @include animation(all, .3s);
    }
  }
}
.owl-carousel {
    .owl-nav {
        position: absolute;
        top: -45px;
        right: 0;
        z-index: 50;
    }
    .owl-prev, .owl-next {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: $blue;
        text-align: center;
        display: inline-block;
        &:after {
            color: $wh;
            font-size: 20px;
            font-family: 'icomoon' !important;
        }
    }
    .owl-prev {
        margin-right: 8px;
        &:after {
            margin-left: -2px;
            content: "\f053";
        }
    }
    .owl-next {
        &:after {
            content: "\f054";
        }
    }
}