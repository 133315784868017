header#header {
    .main-nav {
        nav.menu {
            ul {
                li {
                    a {
                        &.kap-sante {
                            &:hover {
                                color: $bgSante !important;
                            }
                            &:after {
                                background: $bgSante !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.univers-sante {
    .breadcrumbs a {
        color: $bgSante;
    }
    .content h2 {
        color: $bl;
    }
    .content h4 {
        color: $bgSante;
    }
    header#header {
        .main-nav {
            nav.menu {
                ul {
                    li {
                        &.current {
                            a {
                                color: $bgSante;
                            }
                        }
                        a {
                            &:hover {
                                color: $bgSante;
                            }
                            &:after {
                                background: $bgSante;
                            }
                            &.user {
                                background: $bgSante;
                                &:hover {
                                    background: darken($bgSante, 15%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-presentation .text-container h1.tag-center {
            background: $bgSante;
        }
    }
    header#header .main-nav nav.menu ul li a.user {
        background: $bgSante !important;
    }

    #hp_main_section {
        h2 {
            &:after {
                background-color: $bgSante;
            }
        }
    }
    .section-hp {
        h3 {
            color: $bgSanteLight;
        }
    }
    .disciplines-bloc {
        .item {
            &:hover {
                .item-bg {
                    background-color: $bgSante;
                }
            }
        }
    }
    .section-cta {
        a.action-link {
            background: $bgSante;
            &:hover {
                background: lighten($bgSante, 5%);
            }
        }
    }
    .single-view {
        .base-title {
            h1 {
                color: $bgSante;
                span {
                    color: $bgSanteLight;
                }
            }
        }
    }
    .page-title {
        h1 {
            color: $bgSanteLight;
        }
    }
    .single-title {
        h1 {
            color: $bgSanteLight;
        }
    }
    .cms-view {
        .single-title {
            h1 {
                color: $bgSante;
            }
        }
    }
    .owl-carousel {
        .owl-prev, .owl-next {
            background: $bgSante;
        }
    }
    .content h3 {
        color: $bgSante;
    }
    #contact_page .ph, #contact_page .em a {
        color: $bgSante;
    }
    #contact_page .actions .btn-send {
        background: $bgSante;
    }
    .disciplines-bloc .item .item-card h4 {
       
    }
    .disciplines-bloc .item .item-card .more {
        color: $bgSante;
    }
    a.large-button {
        background: $bgSante;
    }
    .owl-dots .owl-dot.active span {
        background: $bgSante;
    }
    .disciplines-bloc .item {
        padding-top: 0;
        .item-bg {
            position: relative;
            width: 100%;
            background-position: bottom center;
            height: 235px;
        }
        .item-card {
            margin: -2px 0 0 0;
            border-right: solid 1px #e6e6e6;
            h4 {
                 background: $bgSante;
                 border-left: solid 1px $bgSante;
                 border-right: solid 1px $bgSante;
                 margin: 0 -40px 20px -20px;
            }
        }
    }
    .disciplines-bloc .owl-carousel-disciplines .item .item-card {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .univers-sante {
        &.menu-open header#header .main-nav nav.menu ul li a {
            background: $bgSanteLight;
            color: $bgSante;
        }
    }
}
@media (max-width: 768px) {
    .univers-sante {
        #hp_main_section {
            .col-img {
                display: none;
            }
        }
    }
}