.tag-center {
    display: inline-block;
    padding: 0 14px;
    border-radius: 5px;
    @include font("700");
    font-size: 36px;
    line-height: 60px;
    color: $wh;
    text-transform: uppercase;
    margin-bottom: 45px;
    &.tag-kap-care {
        background: $blueLight;
    }
    &.tag-kap-care-sport {
        background: $bgSportLight;
    }
    &.tag-kap-care-sante {
        background: $bgSante;
    }
    &.tag-kap-care-bien-etre {
        background: $bgBienetre;
    }
}
.post-list {
    .post-list-item {
        margin-bottom: 45px;
        .link-img {
            display: block;
            color: $fontColor;
            .placeholder {
                width: 100%;
                min-height: 190px;
                position: relative;
                display: block;
                overflow: hidden;
                .bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                    @include prefix(transform, 'scale(1)');
                    @include animation(all, .3s);
                }
            }
            &:hover {
                .placeholder .bg {
                    opacity: 0.8;
                    @include prefix(transform, 'scale(1.24)');
                }
            }
        }
        .title {
            margin: 15px 0;
            @include font("700");
            font-size: 28px;
            line-height: 32px;
            color: $darkBl;
            display: block;
        }
        .desc {
            font-size: 18px;
            line-height: 24px;
            display: block;
        }
        .more {
            display: block;
            margin-top: 20px;
            display: inline-block;
            @include font("700");
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
        }
    }
    a.large-button {
        margin-bottom: 45px;
    }
}

.single-news {

    .single-title {
        width: 80%;
        margin: 0 auto;
        border-bottom: solid 1px #eeeeee;
        h2 {
            width: 80%;
        }
    }
}

.post-main-content {
    width: 80%;
    margin: 0 auto;
    .post-top-infos {
        padding: 15px 0;
        overflow: hidden;
        border-bottom: solid 1px #eeeeee;
        .date {
            font-size: 16px;
            color: #999999;
            float: left;
            margin-left: 30px;
        }
    }
    .content {
        margin-top: 26px;
        img {
            @extend .img-fluid;
        }
        .default {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 60px;
        }
        .bloc {
            margin-left: -10%;
            margin-right: -10%;
            margin-bottom: 60px;
        }
    }
}

.univers-list-post.single {
    .owl-stage {
        margin: 0 auto;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .tag-center {
        font-size: 26px;
        line-height: 50px;
    }
    .post-list {
        .post-list-item {
            .link-img {
                .placeholder {
                    min-height: 260px;
                }
            }
        }
    }
}