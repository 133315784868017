*:focus {
    outline: none !important;
}
a:focus {
	text-decoration: none;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
strong {
	font-weight: 700;
}
.t-a-l {
    text-align: left;
}

.t-a-c {
    text-align: center;
}

.t-a-r {
    text-align: right;
}

.d-i-b {
    display: inline-block;
}

// Chrome
input[type="button"], input[type="submit"], input[type="reset"], input[type="file"]::-webkit-file-upload-button, button {
	-webkit-border-radius: 0;
}

.flex-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    margin: 0 auto;
    .box {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-item-align: stretch;
        -webkit-align-self: stretch;
        align-self: stretch;
        padding: 0;
        margin: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        p {
            font-size: 18px;
            line-height: 20px;
        }
        ul {
            list-style-type: square;
            margin: 0;
            padding: 0 0 0 15px;
            li {
                line-height: 18px;
                margin-bottom: 15px;
            }
        }
    }
    .align-middle {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    }
}

/*********************************/
/**********  MARGIN  *************/
.m-auto {margin:auto;}
.m-0 {margin:0 !important;}
.m-5 {margin:5px !important;}
.m-10 {margin:10px !important;}
.m-15 {margin:15px !important;}
.m-20 {margin:20px !important;}
.m-30 {margin:30px !important;}
.m-40 {margin:40px !important;}
.m-60 {margin:60px !important;}

/****  Margin Top  ****/
.m-t-0 {margin-top:0px !important;}
.m-t-5 {margin-top:5px !important; }
.m-t-10 {margin-top:10px !important;}
.m-t-15 {margin-top:15px !important;}
.m-t-20 {margin-top:20px !important;}
.m-t-30 {margin-top:30px !important;}
.m-t-40 {margin-top:40px !important;}
.m-t-50 {margin-top:40px !important;}
.m-t-60 {margin-top:60px !important;}
.m-t-70 {margin-top:70px !important;}
.m-t-80 {margin-top:80px !important;}
.m-t-100 {margin-top:100px !important;}
.m-t-120 {margin-top:120px !important;}
.m-t-150 {margin-top:150px !important;}
.m-t--10 {margin-top:-10px !important;}

/****  Margin Bottom  ****/
.m-b-0 {margin-bottom:0px !important;}
.m-b-5 {margin-bottom:5px !important;}
.m-b-10 {margin-bottom:10px !important;}
.m-b-15 {margin-bottom:15px !important;}
.m-b-20 {margin-bottom:20px !important;}
.m-b-30 {margin-bottom:30px !important;}
.m-b-40 {margin-bottom:40px !important;}
.m-b-50 {margin-bottom:50px !important;}
.m-b-60 {margin-bottom:60px !important;}
.m-b-70 {margin-bottom:70px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-100{margin-bottom:100px !important;}

/****  Margin Left  ****/
.m-l-0 {margin-left:0 !important;}
.m-l-5 {margin-left:5px !important;}
.m-l-10 {margin-left:10px !important;}
.m-l-20 {margin-left:20px !important;}
.m-l-30 {margin-left:30px !important;}
.m-l-40 {margin-left:40px !important;}
.m-l-60 {margin-left:60px !important;}
.m-l-80 {margin-left:80px !important;}
.m-l--10 {margin-left:-10px !important;}

/****  Margin Right  ****/
.m-r-0 {margin-right:0 !important;}
.m-r-5 {margin-right:5px !important;}
.m-r-10 {margin-right:10px !important;}
.m-r-20 {margin-right:20px !important;}
.m-r-30 {margin-right:30px !important;}
.m-r-40 {margin-right:40px !important;}
.m-r-60 {margin-right:60px !important;}
.m-r-80 {margin-right:80px !important;}

/****  Padding  ****/
.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}
.p-60 {padding:60px !important;}
.p-100 {padding:100px !important;}

/****  Padding Top  ****/
.p-t-0 {padding-top:0 !important;}
.p-t-10 {padding-top:10px !important;}
.p-t-15 {padding-top:15px !important;}
.p-t-20 {padding-top:20px !important;}
.p-t-30 {padding-top:30px !important;}
.p-t-40 {padding-top:40px !important;}
.p-t-60 {padding-top:60px !important;}
.p-t-80 {padding-top:80px !important;}
.p-t-100 {padding-top:100px !important;}
.p-t-120 {padding-top:120px !important;}

/****  Padding Bottom  ****/
.p-b-0 {padding-bottom:0 !important;}
.p-b-10 {padding-bottom:10px !important;}
.p-b-20 {padding-bottom:20px !important;}
.p-b-30 {padding-bottom:30px !important;}
.p-b-40 {padding-bottom:40px !important;}
.p-b-60 {padding-bottom:60px !important;}
.p-b-80 {padding-bottom:80px !important;}
.p-b-100 {padding-bottom:100px !important;}
.p-b-120 {padding-bottom:120px !important;}

/****  Padding Left  ****/
.p-l-0 {padding-left:0 !important;}
.p-l-5 {padding-left:5px !important;}
.p-l-10 {padding-left:10px !important;}
.p-l-15 {padding-left:15px !important;}
.p-l-20 {padding-left:20px !important;}
.p-l-30 {padding-left:30px !important;}
.p-l-40 {padding-left:40px !important;}
.p-l-60 {padding-left:60px !important;}
.p-l-100 {padding-left:100px !important;}

/* Padding Right  ****/
.p-r-0 {padding-right:0 !important;}
.p-r-5 {padding-right:5px !important;}
.p-r-10 {padding-right:10px !important;}
.p-r-15 {padding-right:15px !important;}
.p-r-20 {padding-right:20px !important;}
.p-r-30 {padding-right:30px !important;}
.p-r-40 {padding-right:40px !important;}
.p-r-60 {padding-right:60px !important;}
.p-r-80 {padding-right:80px !important;}
.p-r-90 {padding-right:90px !important;}
.p-r-100 {padding-right:100px !important;}