.offers-list {
    .item {
        border: solid 1px $bgSport;
        margin-bottom: 15px;
        h3 {
            @include font("700");
            font-size: 24px;
            background: $bgSport;
            color: $wh;
            padding: 20px 30px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        .activity {
            padding: 25px 30px;
            .activity-label {
                @include font("700");
                color: $action;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 25px;
            }
            .activity-subtitle {
                @include font("400", 1);
                font-weight: 500;
                color: #999999;
                font-size: 15px;
                line-height: 15px;
                display: block;
            }
            .description {
                margin-top: 20px;
                h4 {
                    color: $darkBlue;
                    @include font("700");
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 8px;
                }
                ul {
                    padding: 0;
                    margin: 0 0 0 20px;
                    li {
                        @include font("300");
                        font-size: 16px;
                        color: #666666;
                        em {
                            @include font("400");
                            font-style: normal;
                            font-size: 14px;
                            margin-top: -8px;
                            display: block;
                            color: #999999;
                        }
                        strong {
                            @include font_ex("400");
                        }
                    }
                }
                p {
                    @include font("500");
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #999999;
                }
                h3 {
                    background: none;
                    color: $darkBlue;
                    font-size: 24px;
                    padding: 0;
                    margin-bottom: 5px;
                }
                h5 {
                    @include font("700");
                    font-size: 22px;
                    color: $darkBlue;
                }
                .line {
                    margin-bottom: 18px;
                    display: block;
                    h4 {
                        color: $action;
                        @include font("700");
                        font-size: 15px;
                        line-height: 18px;
                        text-transform: uppercase;
                        em {
                            @include font("300");
                            font-style: normal;
                            color: #999999;
                            text-transform: none;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .offer-bloc {
                background: #ffa94d;
                padding: 20px;
                color: $wh;
                text-align: center;
                text-transform: uppercase;
                img {
                    width: 124px;
                    margin: 0 auto;
                    margin-bottom: 12px;
                }
                .percent {
                    @include font("700");
                    font-size: 72px;
                    line-height: 72px;
                    display: block;
                }
                .base {
                    @include font("700");
                    font-size: 30px;
                    line-height: 25px;
                    display: block;
                }
                .sub {
                    @include font("700");
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 18px;
                    display: block;
                    margin-top: 8px;
                }
                .condition {
                    @include font("400");
                    font-size: 16px;
                    line-height: 18px;
                    text-transform: none;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
    .col1 {
        .item {
            border-color: $action;
            border-bottom: none;
            h3 {
                background: $action;
            }
            .description {
                ul {
                    margin-left: 30px;
                }
                p {
                    @include font("400", 1);
                    font-size: 15px;
                    line-height: 18px;
                    text-transform: none;
                }
            }
            .activity {
                border-bottom: solid 1px $action;
                .offer-bloc {
                    background: #ff922b;
                    .base {
                        font-size: 24px;
                        line-height: 24px;
                    }
                    .sub {
                        @include font("200");
                        font-size: 18px;
                        line-height: 22px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .offers-list {
        .item {
            h3 {
                font-size: 22px;
            }
            .activity {
                .activity-label {
                    font-size: 28px;
                }
                .description {
                    ul {
                        margin-left: 20px;
                    }
                }
                .offer-bloc {
                    margin-top: 30px;
                }
            }
        }
    }
}
@media (max-width: 992px) {
    .offers-list {
        .item {
            h3 {
                min-height: 84px;
                font-size: 20px;
                padding-left: 20px;
            }
            .activity {
                padding: 25px 20px;
                .activity-label {
                    font-size: 24px;
                }
                .description {
                    h3 {
                        min-height: auto;
                    }
                }
                .offer-bloc {
                    .sub {
                        font-size: 15px;
                    }
                }
            }
        }
        &.col1 .item .activity .offer-bloc .sub {
            font-size: 16px;
        }
    }
}
@media (max-width: 768px) {
    .offers-list {
        .item {
            h3 {
                min-height: auto;
            }
        }
    }
}