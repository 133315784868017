.disciplines-bloc {
    &.section-hp {
        z-index: 20;
    }
    .owl-dots-container {
        margin-top: 20px;
    }
    .button-all {
        margin-top: 20px;
        text-align: center;
    }
    &.inline-cms {
        .item {
            margin-bottom: 15px;
        }
    }
    .owl-carousel-disciplines {
        .item {
            display: block;
            .item-card {
                width: calc(100% - 15px);
                margin: 0;
                h4 {
                    margin-left: -21px;
                }
            }
            .item-bg {
                width: 100%;
                right: 0;
            }
        }
    }
    &.inline-cms {
        margin-bottom: 95px;
    }
    .item {
        padding-top: 230px;
        position: relative;
        overflow: hidden;
        .item-bg {
            background-size: cover;
            background-position: center;
            width: calc(100% - 15px);
            height: 100%;
            position: absolute;
            left: 0;
            right: 15px;
            top: 0;
            bottom: 0;
            z-index: 0;
            background-blend-mode: multiply;
            @include animation(all, .3s);
        }
        .item-card {
            z-index: 1;
            background: $wh;
            margin-left: -15px;
            margin-right: 15px;
            padding: 0 40px 10px 20px;
            position: relative;
            border-left: solid 1px lighten($lightGrey, 10%);
            border-bottom: solid 1px lighten($lightGrey, 10%);
            h4 {
                @include font("900");
                margin-left: -20px;
                margin-right: -40px;
                padding: 15px 20px;
                background: $action;
                color: $wh;
                font-size: 16px;
                line-height: 22px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
                color: $fontColor;
                line-height: 24px;
            }
            em {
                color: lighten($darkBl, 30%);
                @include font("700", 1);
            }
            .more {
                color: $action;
                @include font("700");
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
            }
        }
        &:hover {
            .more {
                text-decoration: underline;
            }
        }
    }
}
section.activites {
    margin-top: 40px;
}
.activites {
    .item {
        border: solid 1px $action;
        padding: 20px;
        border-radius: 4px;
        border-bottom: solid 5px $action;
        overflow: hidden;
        margin-bottom: 20px;
        .infos {
            margin-top: 20px;
            margin-bottom: 20px;
            display: block;
            .flag-icon {
                margin-right: -1px;
            }
        }
        img {
            margin-bottom: 20px;
            min-width: 100%;
        }
        h3 {
            @include font("900");
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 10px;
            color: $action;
        }
        h4 {
            @include font("900");
            font-size: 16px;
            margin-bottom: 10px;
            strong {
                text-transform: uppercase;
            }
        }
        .desc {
            font-size: 16px;
            line-height: 24px;
            color: $darkBlue;
        }
    }
}
@media (max-width: 1200px) {
    .disciplines-bloc {
        .item {
            .item-card {
                h4 {
                    font-size: 14px;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .disciplines-bloc {
        .item {
            .item-card {
                margin-right: 0;
                border: solid 1px lighten($lightGrey, 10%);
                border-top: none;
            }
        }
    }
}
@media (max-width: 768px) {
    .section-hp {
        &.disciplines-bloc {
           margin-top: 80px; 
        }
    }
    .disciplines-bloc .item {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        .item-bg {
            width: 100%;
        }
        .item-card {
            margin-right: 0;
            border: none;
            border-left: solid 1px lighten($lightGrey, 10%);
            border-bottom: solid 1px lighten($lightGrey, 10%);
        }
    }
}