header#header {
    .main-nav {
        nav.menu {
            ul {
                li {
                    a {
                        &.kap-sport {
                            &:hover {
                                color: $bgSport !important;
                            }
                            &:after {
                                background: $bgSport !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.univers-sport {
    header#header {
        .main-nav {
            nav.menu {
                ul {
                    li {
                        &.current {
                            a {
                                color: $bgSport;
                            }
                        }
                        a {
                            &:hover {
                                color: $bgSport;
                            }
                            &:after {
                                background: $bgSport;
                            }
                            &.user {
                                background: $bgSport;
                                &:hover {
                                    background: darken($bgSport, 15%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-presentation .text-container h1.tag-center {
            background: $bgSport;
        }
    }
    header#header .main-nav nav.menu ul li a.user {
        background: $bgSport !important;
    }

    #hp_main_section {
        h2 {
            &:after {
                background-color: $bgSport;
            }
        }
    }
    .section-hp {
        h3 {
            color: $bgSportLight;
        }
    }
    .disciplines-bloc {
        .item {
            &:hover {
                .item-bg {
                    background-color: $bgSport;
                }
            }
        }
    }
    .single-view {
        .base-title {
            h1 {
                color: $bgSport;
                span {
                    color: $bgSportLight;
                }
            }
        }
    }
    .page-title {
        h1 {
            color: $bgSportLight;
        }
    }
    .single-title {
        h1 {
            color: $bgSportLight;
        }
    }
    .cms-view {
        .single-title {
            h1 {
                color: $bgSport;
            }
        }
    }
}
@media (max-width: 992px) {
    .univers-sport {
        &.menu-open header#header .main-nav nav.menu ul li a {
            background: $bgSportLight;
            color: $bgSport;
        }
    }
}