/*!
MAIN STYLE
*/

html {
	background: #fff;
	font-size: 16px;
	line-height: 1.9em;
	-webkit-font-smoothing: antialiased;
	@include font();
	font-weight: 400;
}

body {
	background: #fff;
	font-size: 16px;
	line-height: 1.9em;
	-webkit-font-smoothing: antialiased;
	@include font();
	font-weight: 400;
	box-shadow: none;
  // &::after {
  //   position: fixed;
  //   content: '';
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100%;
  //   visibility: v;
  //   opacity: 0;
  //   -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  //   -moz-transition: opacity .3s 0s, visibility 0s .3s;
  //   transition: opacity .3s 0s, visibility 0s .3s;
  //   background: rgba(71, 55, 78, 0.8);
  // }
}
#aoc_container {
	display: none;
	background: rgba(#000, 0.9);
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
	position: fixed;
	#aoc_text {
		font-size:160px;
		line-height: 160px;
		text-align: center;
		height: 160px;
		position: absolute;
		top: 50%;
		margin-top: -290px;
		color:rgb(247, 227, 95);
  		text-shadow:1px 1px 12px rgb(255, 127, 0);
  		width: 600px;
		left: 50%;
		margin-left: -250px;
		animation: blink-animation 1s steps(5, start) infinite;
  		-webkit-animation: blink-animation 1s steps(5, start) infinite;
	}
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.aerobic {
	max-width: 100%;
	width: 600px;
	height: 330px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -115px;
	margin-left: -250px;
	z-index: 9999;
	overflow: hidden;
	video {
		max-width: 100%;
	}
}
a {
	&:hover, &:active {
		text-decoration: none;
	}
}
* {
	strong {
		@include font("700");
		em {
			@include font("700", "1");
		}
	}
	em {
		@include font("", "1");
	}
}

sup {
	margin-right: 4px;
	left: 2px;
	font-size: 50%;
}

.page-title {
	h1 {
		@include font("700");
		font-size: 120px;
		line-height: 180px;
		text-transform: uppercase;
		color: $blueLight;
	}
}
.single-title {
	text-align: center;
	padding: 45px 0 30px 0;
	h1 {
		@include font("700");
		font-size: 72px;
		line-height: 72px;
		text-transform: uppercase;
		color: $blueLight;
	}
	h2 {
		@include font("300");
		font-size: 24px;
		line-height: 36px;
		color: $darkBlue;
		margin: 0 auto;
		margin-top: 26px;
	}
}
.cms-view {
	.single-title {
		h1 {
			color: $blue;
		}
	}
}

.content {
	p {
		font-size: 16px;
		color: $fontColor;
		line-height: 24px;
		margin-bottom: 20px;
	}
	h2 {
		@include font("700");
		font-size: 30px;
		line-height: 24px;
		color: $action;
		margin: 20px 0;
	}
	h3 {
		@include font("700");
		font-size: 22px;
		color: $action;
		margin: 20px 0;
	}
	h4 {
		@include font("700");
		font-size: 18px;
		text-transform: uppercase;
		color: $action;
		margin: 20px 0;
	}
	ul {
		margin: 20px 0;
		padding: 0 0 0 20px;
		li {

		}
	}
	blockquote {
		margin-left: -10%;
		margin-right: -10%;
	}
	blockquote, blockquote p {
		@include font("200", 1);
		font-size: 36px;
		line-height: 44px;
		color: $action;
		text-align: center;
	}
}

.section-page-large {
	width: 86%;
    margin: 0 auto;
    //margin-top: -30px;
    position: relative;
}

.breadcrumbs {
	font-size: 16px;
	a {
		color: $blue;
		font-weight: 700;
		&:after {
			content: "/";
			display: inline-block;
			margin: 0 5px;
		}
	}
	color: $fontColor;
}

a.more, span.more {
	color: $action;
}

a.link-type-kap-care {
    color: $blueLight;
}
a.link-type-kap-care-sport {
    color: $bgSportLight;
}
a.link-type-kap-care-sante {
    color: $bgSante;
}
a.link-type-kap-care-bien-etre {
    color: $bgBienetre;
}
a.large-button {
	padding: 16px 35px;
	display: inline-block;
	@include font("300");
	font-size: 18px;
	line-height: 24px;
	text-transform: uppercase;
	background: $action;
	color: $wh;
	text-align: center;
}

.action-link {
    width: 80%;
    display: inline-block;
    margin: 0 auto;
    background-color: $action;
    text-align: left;
    padding: 30px 50px 30px 40px;
    color: $wh;
    @include animation(background, .3s);
    .icon {
    	font-size: 60px;
    }
    span, i {
    	float: left;
    }
    span {
    	@include font("200");
    	font-size: 20px;
    	text-transform: uppercase;
    	strong {
    		font-weight: 500;
    		margin-right: 10px;
    	}
    	line-height: 60px;
    	margin-left: 20px;
    	@include animation(transform, .3s);
    }
    i.icon-arrow_up {
    	float: right;
    	line-height: 60px;
    	font-size: 32px;
    	@include animation(transform, .3s);
    }
    &:hover {
    	color: $wh;
    	background: lighten($action, 5%);
    	span {
    		@include prefix(transform, 'translateX(10%)');
    	}
    	i.icon-arrow_up {
	    	@include prefix(transform, 'translateX(-100%)');
	    }
    }
}

.info-area {
	width: 80%;
    display: inline-block;
    margin-top: 25px;
    margin-bottom: -20px;
    background-color: $action;
    text-align: left;
    padding: 30px 50px 25px 40px;
    color: $wh;
    @include animation(background, .3s);
    .icon {
    	font-size: 60px;
    	width: 60px;
    }
    .desc, i {
    	float: left;
    }
    .desc {
    	margin-left: 20px;
    	width: calc(100% - 80px);
    	h4 {
			@include font("900");
    		font-size: 20px;
    		line-height: 60px;
    		text-transform: uppercase;
    		margin: 0;
    	}
    	p {
			font-size: 18px;
			line-height: 24px;
			margin: 0;
    	}
    }
}

.main-content {
	margin-top: 20px;
}

body {
	&.univers-sport {
		
		// a.more, span.more {
		// 	color: $bgSport;
		// }
		// a.large-button {
		// 	background: $bgSport;
		// }
	}
	&.univers-sante {
		.page-title {
			h1 {
				color: $bgSanteLight;
			}
		}
		.single-title {
			h1 {
				color: $bgSante;
			}
		}
		// a.more, span.more {
		// 	color: $bgSante;
		// }
		// a.large-button {
		// 	background: $bgSante;
		// }
	}
	&.univers-bien-etre {
		.page-title {
			h1 {
				color: $bgBienetreLight;
			}
		}
		.single-title {
			h1 {
				color: $bgSportLight;
			}
		}
		// a.more, span.more {
		// 	color: $bgBienetre;
		// }
		// a.large-button {
		// 	background: $bgBienetre;
		// }
	}
}

.loader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba($wh, 0.9);
	width: 100%;
	height: 100%;
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	@include animation(opacity, .3s);
}

body.loading {
	overflow: hidden;
	.loader {
		opacity: 1;
		visibility: visible;
	}
}

.sk-cube-grid {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -20px 0 0 -20px;
}

.sk-cube-grid .sk-cube {
	width: 33%;
	height: 33%;
	background-color: $blue;
	float: left;
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	} 35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1); 
	}
}

@keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	} 35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1);
	} 
}

.fancybox-button--zoom {display: none !important;}

@media (max-width: 992px) {
	.page-title {
		h1 {
			font-size: 100px;
			line-height: 140px;
		}
	}
	.single-title {
		h1 {
			font-size: 62px;
			line-height: 62px;
		}
	}
	.action-link {
		i {
			width: 60px;
		}
		span {
			width: calc(100% - 112px);
			line-height: 24px;
			strong {
				display: block;
			}
		}
		.icon-arrow_up {
			width: 32px;
		}
	}
}
@media (max-width: 768px) {
	.page-title {
		h1 {
			font-size: 80px;
			line-height: 120px;
		}
	}
	.single-title {
		h1 {
			font-size: 42px;
			line-height: 42px;
		}
	}
	.action-link {
		width: 100%;
		.icon-arrow_up {
			display: none;
		}
	}
	.info-area {
		.desc {
			float: none;
			width: auto;
			margin-left: 0;
			h4 {
				float: left;
				margin-left: 20px;
			}
			p {
				clear: both;
				display: inline-block;
				margin-top: 20px;
			}
		}
	}
}
@media (max-width: 600px) {
	.page-title {
		h1 {
			width: 100%;
			overflow: hidden;
			font-size: 50px;
			line-height: 120px;
		}
	}
	.single-title {
		h1 {
			font-size: 32px;
			line-height: 32px;
		}
	}
	a.large-button {
		padding: 16px 15px;
		font-size: 16px;
		line-height: 20px;
	}
	.info-area {
		.desc {
			h4 {
				font-size: 16px;
			}
		}
	}
}
@media (max-width: 500px) {
	.action-link {
		padding: 30px 15px 30px 15px;
		i.icon {
			font-size: 45px;
			width: 45px;
		}
		span {
			width: calc(100% - 70px);
			margin-left: 10px;
			font-size: 14px;
			em {
				display: none;
			}
		}
	}
	.info-area {
		width: 100%;
		padding: 30px 20px 25px 20px;
		i.icon {
			width: 35px;
			font-size: 35px;
		}
		.desc {
			h4 {
				margin-left: 10px;
				font-size: 14px;
				line-height: 35px;
			}
			p {
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}