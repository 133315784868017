.cms-view {
    &.base-cms-std {
        .alignleft {
            float: left;
            max-width: 50%;
            margin-right: 30px;
            margin-bottom: 30px;
            width: auto;
            height: auto;
        }
        .content {
            text-align: left;
            .default {
                width: 100%;
            }
            ul {

            }
        }
        .single-title {
            text-align: left;
        }
    }
    .content {
        text-align: center;
        margin-bottom: 30px;
        .default {
            margin: 0 auto;
            width: 80%;
            h2 {
                margin-top: 0;
            }
        }
    }
}
.single-view {
    background-position: 100% top;
    background-repeat: no-repeat;
    .base-title {
        padding: 45px 0 0 0;
        margin-bottom: 130px;
        h1 {
            @include font("700");
            color: $blue;
            font-size: 72px;
            text-transform: uppercase;
            margin-bottom: 35px;
            margin-left: -5px;
            span {
                font-size: 114px;
                display: block;
                color: $blueLight;
            }
        }
        p {
            font-size: 30px;
            line-height: 40px;
            color: $darkBlue;
            width: 60%;
        }
    }
}

@media (max-width: 1200px) {
    .single-view {
        .base-title {
            p {
                font-size: 25px;
                line-height: 36px;
            }
        }
    }
}
@media (max-width: 992px) {
    .single-view {
        background-position: 160% top;
        .base-title {
            padding: 20px 0 0 0;
            h1 {
                font-size: 60px;
                span {
                    font-size: 90px;
                }
            }
            p {
                width: 80%;
                padding-right: 10px;
            }
        }
    }
}
@media (max-width: 768px) {
    .single-view {
        background: none !important;
        .base-title {
            margin-bottom: 60px;
            h1 {
                font-size: 36px;
                span {
                    font-size: 72px;
                }
            }
            p {
                width: 100%;
                font-size: 24px;
            }
        }
    }
}
@media (max-width: 620px) {
    .single-view {
        .base-title {
            h1 {
                font-size: 24px;
                span {
                    font-size: 52px;
                }
            }
            p {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
}