.planning-link {
    margin-bottom: 60px;
    em {
        font-style: normal;
    }
    strong {
        em {
            @include font("700");
        }
    }
}
.planning-inline {
    .planning-link {
        margin-bottom: 0;
    }
}

$regularAquaColor: #52c3dc;
$regularColor : #52c3dc;
$flameColor: #f1487f;

$smallAquaColor: $bgSport;
$mediumAquaColor: $bgSport;

$hardAquaColor: $bgSport;

$smallMillsColor: $regularAquaColor;
$mediumMillsColor: $regularAquaColor;
$regularMillsColor: $regularAquaColor;
$hardMillsColor: $regularAquaColor;

.planning-notices {
    margin: 0 0 15px 0;
    padding: 0;
    width: 60%;
    float: left;
    margin-top: -60px;
    li {
        @include font("700");
        font-weight: 500;
        list-style: none;
        display: inline-block;
        line-height: 18px;
        margin-right: 15px;
        font-size: 12px;
        color: $darkBl;
        position: relative;
        text-transform: uppercase;
        span.flag-label {
            display: inline-block;
            margin-left: 8px;
        }
        // &:before {
        //     position: absolute;
        //     display: block;
        //     // width: 12px;
        //     // height: 12px;
        //     // border-radius: 50%;
        //     left: 0;
        //     top: -3px;
        //     font-family: 'icomoon' !important;
        //     color: $flameColor;
        //     font-size: 16px;
        // }
        &.area_mills {
            span.flag-icon {
                &:before {
                    content: "\e905";
                }
            }
        }
        &.area_aqua {
            span.flag-icon {
                &:before {
                    content: "\e904";
                }
            }
        }
    }
}
.flag-icon {
    display: inline-block;
    color: $flameColor;
    font-size: 16px;
    margin-right: -5px;
    &:before {
        content: "\e905";
        font-family: 'icomoon' !important;
    }
}
.planning-switch {
    width: 50%;
    // float: left;
    // margin-top: -35px;
    margin-top: 50px;
    label {
        float: left;
        line-height: 55px;
        margin-right: 20px;
        font-size: 22px;
        @include font("700");
        font-weight: 500;
        color: $darkBlue;
    }
    .choices {
        display: inline-block;
        width: 52%;
        text-align: left;
        .choices__inner {
            @include font("700");
            font-size: 16px;
            background: $wh;
            border-color: #dddddd;
            border-radius: 6px;
        }
        .choices__list--dropdown .choices__item {
            font-size: 14px;
            @include font("700");
            font-weight: 500;
        }
    }
}
.planning-times {
    .owl-nav {
        display: none;
    }
    .owl-carousel .owl-stage-outer {
        overflow: inherit;
    }
    .owl-carousel {
        display: block;
    }
    .owl-carousel.owl-hidden {
        opacity: 1;
    }
    &#area_aqua {
        display: none;
    }
    .col-title {
        position: relative;
        @include font("700");
        font-weight: 500;
        font-size: 18px;
        color: #999999;
        text-transform: uppercase;
        padding: 16px 0;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            height: 1px;
            width: calc(100% + 30px);
            background: #d8d8d8;
        }
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            height: 1px;
            width: calc(100% + 30px);
            background: #d8d8d8;
        }
    }
    .col-container {
        float: left;
        width: calc(16.666667% - 15px);
        margin-right: 15px;
    }
    .owl-loaded {
        .col-container {
            float: none;
            width: auto;
            margin-right: 0;
        }
    }
    .col-container.last {
        .col-title {
            &:before, &:after {
                width: 100%;
            }
        }
    }
    .col-planning {
        margin-top: 25px;
        .col-group {
            margin-bottom: 20px;
            &.marging {
                margin-top: 31px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .item {
                margin-bottom: 3px;
                min-height: 55px;
                border: solid 1px #e9ecef;
                &.empty {
                    background: #fbfbfb;
                }
                span {
                    display: block;
                    width: 100%;
                    @include font("700");
                    font-size: 14px;
                    line-height: 24px;
                    padding: 0 10px;
                }
                .hour {
                    color: $wh;
                }
                .title {
                    color: $darkBl;
                    text-transform: uppercase;
                    line-height: 30px;
                    .flags {
                        display: block;
                        margin-top: -5px;
                        .flag-icon {
                            margin-right: -1px;
                            line-height: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            .close-time {
                text-align: center;
                background: #e9ecef;
                display: block;
                width: 100%;
                @include font("700");
                font-size: 14px;
                line-height: 24px;
                padding: 35px 0;
                text-transform: uppercase;
            }
        }
        &.mills {
            .default {
                border-color: $regularColor;
                .hour {
                    background: $regularColor;
                }
            }
            // .str-small {
            //     border-color: $smallMillsColor;
            //     .hour {
            //         background: $smallMillsColor;
            //     }
            // }
            // .str-medium {
            //     border-color: $mediumMillsColor;
            //     .hour {
            //         background: $mediumMillsColor;
            //     }
            // }
            // .str-regular {
            //     border-color: $regularMillsColor;
            //     .hour {
            //         background: $regularMillsColor;
            //     }
            // }
            // .str-hard {
            //     border-color: $hardMillsColor;
            //     .hour {
            //         background: $hardMillsColor;
            //     }
            // }
        }
        &.aqua {
            .default {
                border-color: #088eaa;
                .hour {
                    background: #088eaa;
                }
            }
            .flag-icon {
                &:before {
                    content: "\e904";
                }
            }
            // .str-small {
            //     border-color: $smallAquaColor;
            //     .hour {
            //         background: $smallAquaColor;
            //     }
            // }
            // .str-medium {
            //     border-color: $mediumAquaColor;
            //     .hour {
            //         background: $mediumAquaColor;
            //     }
            // }
            // .str-regular {
            //     border-color: $regularAquaColor;
            //     .hour {
            //         background: $regularAquaColor;
            //     }
            // }
            // .str-hard {
            //     border-color: $hardAquaColor;
            //     .hour {
            //         background: $hardAquaColor;
            //     }
            // }
            .type-child {
                //088eaa
                border-color: $bgSport !important;
                .hour {
                    background: $bgSport !important;
                }
            }
            .type-mills {
                border-color: $regularColor !important;
                .hour {
                    background: $regularColor !important;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .planning-switch {
        margin-bottom: 20px;
        text-align: right;
        label {
            float: none;
            margin-right: 0;
        }
        .choices {
            width: 80%;
            text-align: left;
        }
    }
}

@media (max-width: 992px) {
    .planning-view {
        .container {
            padding-left: 30px;
            padding-right: 30px;
            width: 90%;
            max-width: 90%;
            margin: 0;
        }
        .planning-times {
            .container {
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .planning-times {
        .col-container.last {
            .col-title {
                &:before, &:after {
                    width: 100%;
                }
            }
        }
        .col-title {
            text-align: center;
        }
        .col-planning .col-group .close-time {
            font-size: 12px;
        }
    }
    .planning-notices {
        width: 100%;
        float: none;
    }
    .planning-switch {
        width: 100%;
        text-align: left;
        margin-top: 0;
        label {
            float: left;
            margin-right: 20px;
        }
    }
}

@media (max-width: 768px) {
    .planning-link {
        margin-top: -100px;
    }
    .section-cta {
        .planning-link {
            margin-top: 0;
        }   
    }
    .planning-notices li {
        display: block;
        float: none;
        margin-bottom: 12px;
    }
    .planning-times {
        .owl-carousel .owl-stage-outer {
            overflow: hidden;
        }
        .owl-nav {
            display: block;
            font-family: 'icomoon' !important;
            font-size: 24px;
            color: $lightGrey;
            .owl-prev {
                position: absolute;
                top: 16px;
                left: 9px;
                &:before {
                    content: "\f053";
                }
            }
            .owl-next {
                position: absolute;
                top: 16px;
                right: 9px;
                &:before {
                    content: "\f054";
                }
            }
        }
        .owl-item {
            .col-planning {
                opacity: 0.3;
                @include animation(opacity, .45s);
            }
            &.active {
                .col-planning {
                    opacity: 1;
                }
            }
        }
        .col-container.last {
            .col-title {
                &:before, &:after {
                    width: calc(100% + 30px);
                }
            }
        }
        .container {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            max-width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .planning-switch {
        label {
            float: none;
            margin-right: 20px;
        }
        .choices {
            width: 100%;
        }
    }
}